import React from "react";
import LinkButton from "../LinkButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import {
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
  Toolbar,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useChatwoot } from "@Lib/chatwoot";
import clsx from "clsx";
import { FontWeight } from "theme/fonts";

const useBaseStyles = makeStyles(theme =>
  createStyles({
    secondary: {
      padding: 0,
      minHeight: 75,
      backgroundColor: theme.palette.background.paper,
    },
    logo: {
      display: "flex",
      flexGrow: 1,
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    noLeft: {
      paddingLeft: 0,
    },
    noRight: {
      paddingRight: 0,
    },
    phoneNumber: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: theme.typography.h6.fontSize,
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    companyName: {
      padding: theme.spacing(1),
      textAlign: "right",
      marginRight: theme.spacing(1),
      fontSize: theme.typography.fontSize + 5,
      color: grey[600],
    },
    pageTitle: {
      fontWeight: FontWeight.Bold,
      fontSize: theme.typography.fontSize + 2,
      textAlign: "center",
    },
    pushRight: {
      justifyContent: "flex-end",
    },
    leftAlign: {
      textAlign: "left",
    },
    rightAlign: {
      textAlign: "right",
    },
    boldText: {
      fontWeight: "bold",
      fontSize: theme.typography.h6.fontSize,
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    gold: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.primary.main,
      margin: theme.spacing(2, 0),
      "&:hover": {
        color: "white",
      },
    },
    phoneContainer: {
      justifyContent: "center",
    },
    chatButtonContainer: {
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-end",
      },
    },
    cartButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    viewCartButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      margin: theme.spacing(1, 0),
      flex: "1 1",
    },
  }),
);

export interface BaseHeaderProps {
  menuComponent?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  showCartButton?: boolean;
}

export const Topbar = (_props: BaseHeaderProps) => {
  const classes = useBaseStyles();
  const chatwoot = useChatwoot();

  return (
    <Toolbar className={classes.secondary}>
      <Container maxWidth="lg">
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12} md={_props.showCartButton ? 6 : 8} sm={4}>
            <Logo className={clsx(classes.logo, classes.noLeft)} />
          </Grid>
          {_props.showCartButton ? (
            <Hidden smDown>
              <Grid item xs={12} md={2} className={classes.cartButtonContainer}>
                <LinkButton href="/cart" className={classes.viewCartButton}>
                  View Cart
                </LinkButton>
              </Grid>
            </Hidden>
          ) : null}
          <Grid container item xs={12} sm={8} md={4} alignItems="center">
            <Grid
              container
              item
              xs={12}
              sm={7}
              className={classes.phoneContainer}
            >
              <Typography className={classes.phoneNumber} variant="h5">
                (888) 238-2388
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={5}
              className={classes.chatButtonContainer}
            >
              <Button
                className={classes.gold}
                variant="contained"
                color="secondary"
                onClick={() => chatwoot?.toggle()}
              >
                <Typography
                  className={classes.boldText}
                  component="p"
                  variant="h5"
                >
                  Live Chat
                </Typography>
              </Button>
            </Grid>
          </Grid>
          {_props.showCartButton ? (
            <Hidden mdUp>
              <Grid item xs={12} md={2} className={classes.cartButtonContainer}>
                <LinkButton href="/cart" className={classes.viewCartButton}>
                  View Cart
                </LinkButton>
              </Grid>
            </Hidden>
          ) : null}
        </Grid>
      </Container>
    </Toolbar>
  );
};

export default Topbar;

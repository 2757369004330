import React from "react";
import LinkIconButton, { LinkIconButtonProps } from "../LinkIconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import useCart from "@Hooks/useCart";
import { Badge } from '@material-ui/core';

const CartMenuButton = (props: Omit<LinkIconButtonProps, "href">) => {
  const { items } = useCart();

  const count = items.length;

  return (
    <LinkIconButton {...props} color="inherit" href="/cart" >
      <Badge badgeContent={count} color="secondary">
        <ShoppingCartIcon />
      </Badge>
    </LinkIconButton>
  );
};

export default CartMenuButton;

import React from "react";
import {
  Badge,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // SwipeableDrawer,
  Drawer,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import AccountIcon from "@material-ui/icons/AccountCircle";
import CartIcon from "@material-ui/icons/ShoppingCart";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import EmailIcon from "@material-ui/icons/Email";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useClient } from "src/context/ClientProvider";
import { signIn, signOut, useUser } from "src/context/AuthProvider";
import LinkButton from "@Components/LinkButton";
import { useRouter } from "next/router";

const useStyles = makeStyles(theme =>
  createStyles({
    list: {
      width: 250,
    },
    button: {
      color: theme.palette.primary.contrastText,
    },
    accountListItemText: {
      textAlign: "center",
    },
  }),
);

const AppDrawer = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
    registerAlert: false,
  });
  const timeout = React.useRef<NodeJS.Timeout>();
  const { client, loading: clientLoading } = useClient();
  const { user } = useUser();
  const { pathname } = useRouter();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, open });
    };

  React.useEffect(() => {
    if (clientLoading) return;

    if (timeout.current && client) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }

    if (!client)
      timeout.current = setTimeout(() => {
        setState(prev => ({ ...prev, registerAlert: true }));
      }, 3000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
    };
  }, [client, clientLoading]);

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {user ? (
          <>
            <ListItem>
              <ListItemIcon>
                <AccountIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={`${user.given_name} ${user.family_name}`}
              />
            </ListItem>
            <ListItem component={LinkButton} href="/dashboard">
              <ListItemText primary="Dashboard" inset={true} />
            </ListItem>
            <ListItem component={Button} onClick={signOut}>
              <ListItemText primary="Logout" inset={true} />
            </ListItem>
            {state.registerAlert ? (
              <ListItem
                component={LinkButton}
                href="/register"
                color="secondary"
              >
                <ListItemText primary="Complete Registration" inset={true} />
              </ListItem>
            ) : null}
          </>
        ) : (
          <ListItem
            component={Button}
            onClick={() => signIn({ returnUrl: pathname })}
          >
            <ListItemIcon>
              <AccountIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Login" />{" "}
          </ListItem>
        )}
        <Divider />
        <ListItem component={LinkButton} href="/">
          <ListItemIcon>
            <HomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem component={LinkButton} href="/about">
          <ListItemIcon>
            <InfoIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem component={LinkButton} href="/contact">
          <ListItemIcon>
            <EmailIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem component={LinkButton} href="/services">
          <ListItemIcon>
            <AssignmentIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem component={LinkButton} href="/cart">
          <ListItemIcon>
            <CartIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Cart" />
        </ListItem>
        <Divider />
        <ListItem component={LinkButton} href="/terms-of-use">
          <ListItemText
            primary="Terms and Conditions"
            primaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>
        <ListItem component={LinkButton} href="/privacy">
          <ListItemText
            primary="Privacy"
            primaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Trace America, LLC"
            primaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <Badge
        badgeContent={"!"}
        color="error"
        invisible={!state.registerAlert}
        overlap="circular"
      >
        <Button onClick={toggleDrawer(true)} className={classes.button}>
          <MenuIcon />
        </Button>
      </Badge>
      <Drawer anchor="left" open={state.open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

export default AppDrawer;

import { Fade, useScrollTrigger } from '@material-ui/core'
import React from 'react'

interface Props {
  threshold?: number;
  window?: () => Window;
  children: React.ReactElement;
}

const FadeScroll = (props: Props) => {
  const { window, threshold, children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: threshold ? threshold : 0,
    target: window ? window() : undefined
  });

  return <Fade in={trigger}>
    {children}
  </Fade>
}

export default FadeScroll;
import React from "react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { ButtonProps } from "@material-ui/core/Button";

import { Button } from '@material-ui/core';

export type LinkButtonProps = Omit<ButtonProps, 'href' | 'classes'> & Pick<NextLinkProps, 'href' | 'as' | 'prefetch'>;

const LinkButton = React.forwardRef<LinkButtonProps, any>(
  ({
    href,
    prefetch,
    as,
    ...props
  }, ref) => (
    <NextLink href={href} as={as} prefetch={prefetch} passHref>
      <Button ref={ref} {...props} />
    </NextLink>
));

LinkButton.displayName = "LinkButton";

export default LinkButton;

import { useUser } from "src/context/AuthProvider";
import { useClient } from "src/context/ClientProvider";
import { IClaims } from "@Lib/auth/session/session";
import { Client } from "@Types";
import React from "react";
import Router from "next/router";
import Debug from "@Utils/debug";

//@ts-ignore
const debug = Debug("lib:hooks:useAuthorization");

export type UseAuthorizationOptions = {
  user?: IClaims | null;
  client?: Client | null;
  redirect?: boolean;
  register?: boolean;
};
const useAuthorization = (options: UseAuthorizationOptions = {}) => {
  const { user, loading: userLoading, error: userError, mutate } = useUser();
  const {
    client,
    loading: clientLoading,
    error: clientError,
    update,
  } = useClient();

  React.useEffect(() => {
    if (options.register || options.redirect) {
      if (
        !(window as any).SILENT_RENEWAL_ACTIVE &&
        ((!user && !userLoading) || userError)
      ) {
        Router.replace(`/not-authenticated?ref=${Router.pathname}`);
        return;
      }
      if (options.register) {
        if (
          !(window as any).SILENT_RENEWAL_ACTIVE &&
          ((!client && !clientLoading) || clientError)
        ) {
          Router.replace(`/not-authorized?ref=${Router.pathname}`);
          return;
        }
      }
    }
  }, [
    options.redirect,
    user,
    userLoading,
    userError,
    options.register,
    client,
    clientLoading,
    clientError,
  ]);

  return {
    user,
    userLoading,
    userError,
    mutateUser: mutate,
    client,
    clientError,
    clientLoading,
    updateClient: update,
  };
};

export default useAuthorization;

import React, { useState, useEffect, createRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  Badge,
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useRouter } from "next/router";
import { signIn, signOut } from "src/context/AuthProvider";

import theme from "theme";
import useAuthorization from "@Hooks/useAuth";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    minheight: 40,
  },
  accountButton: {
    width: "100%",
    padding: theme.spacing(1, 0, 1, 1),
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  accountIcon: {},
  buttonProgress: {
    color: theme.palette.common.white,
    margin: theme.spacing(1.05, 2, 1.05, 0),
  },
  registrationPopper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.tooltip,
    borderRadius: theme.shape.borderRadius,
  },
  finishRegistrationButton: {
    fontWeight: 700,
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
  },
}));

const FinishRegistrationButton = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    marginBottom: theme.spacing(-1),
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      color: theme.palette.common.white,
    },
  },
}))(MenuItem);

const buttonRef = createRef<HTMLButtonElement>();

const AuthenticationMenu = (
  props: Omit<ButtonProps, "onClick" | "endIcon">,
) => {
  // const { clear, items } = useCart();
  const { user, client, clientLoading } = useAuthorization();
  // const { user } = useUser();
  // const { client, loading: clientLoading } = useClient();
  const timeout = React.useRef<NodeJS.Timeout>();
  const { push, pathname } = useRouter();
  const classes = useStyles();
  // const buttonClass = clsx(props.className, classes.accountButton);
  // should we show the register button and the alert icon
  const [showClientAlert, setShowClientAlert] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const userName = `${user?.given_name} ${user?.family_name}`;

  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (!clientLoading && !client && !!user && pathname !== "/register") {
      timeout.current = setTimeout(() => {
        setShowClientAlert(true);
      }, 500);
    } else if (client && timeout.current) {
      clearTimeout(timeout.current);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [user, clientLoading, pathname, client]);

  // useEffect(() => {
  //   if (showClientAlert && items.length > 0) clear();
  // }, [showClientAlert, items.length, clear]);

  useEffect(() => {
    if (!!client && showClientAlert) {
      setShowClientAlert(false);
    }
  }, [showClientAlert, client]);

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={props.className}>
      <div className={classes.wrapper}>
        <Badge
          badgeContent={"!"}
          color="error"
          invisible={!showClientAlert}
          overlap="circular"
        >
          <Button
            ref={buttonRef}
            startIcon={!smDown ? <AccountCircleIcon fontSize="small" /> : null}
            onClick={
              user ? handleMenuOpen : () => signIn({ returnUrl: pathname })
            }
            aria-haspopup="true"
            aria-controls="auth-menu"
            className={classes.accountButton}
          >
            {smDown ? (
              <AccountCircleIcon fontSize="small" />
            ) : user ? (
              userName
            ) : (
              "Login"
            )}
          </Button>
        </Badge>
      </div>
      <Menu
        id="auth-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            push("/dashboard");
          }}
        >
          Dashboard
        </MenuItem>
        <MenuItem onClick={() => signOut({})}>Logout</MenuItem>
        {showClientAlert && (
          <FinishRegistrationButton
            onClick={() => {
              // close them menu when clicking this link
              handleMenuClose();
              push("/register");
            }}
            divider
            className={classes.finishRegistrationButton}
          >
            Finish Registration
          </FinishRegistrationButton>
        )}
      </Menu>
    </div>
  );
};

export default AuthenticationMenu;

import React from "react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { LinkProps as MUILinkProps } from "@material-ui/core/Link";

import { Link as MUILink } from '@material-ui/core';

export type LinkProps = Omit<MUILinkProps, 'href' | 'classes'> & Pick<NextLinkProps, 'href' | 'as' | 'prefetch'>;

const Link = React.forwardRef<LinkProps, any>(({
  href, as, prefetch, ...props}, ref
) => (
  <NextLink href={href} as={as} prefetch={prefetch} passHref>
    <MUILink ref={ref} {...props} />
  </NextLink>
));

Link.displayName = "Link";



// const ForwardRouterLink = React.forwardRef<
//   HTMLAnchorElement,
//   NextLinkProps & { className: any }
// >(({ href, as, children, className, ...props }, ref) => (
//   <NextLink href={href} as={as}>
//     <a className={className} ref={ref} {...props}>
//       {children}
//     </a>
//   </NextLink>
// ));

// const Link = (props: Omit<NextLinkProps & MUILinkProps, "ref">) => (
//   <MUILink component={ForwardRouterLink} {...props} />
// );

export default Link;

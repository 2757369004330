import { useContext } from "react";
import CartContext from "../../src/context/CartContext";
import { Product } from "../../src/interfaces";
// import useClient from "./useClient";
// import useApi from "./useApi";
// import fetcher from "../utils/fetcher";

export type UseCartResult = {
  items: Product[];
  inCart: (v: Product) => boolean;
  clear(storageOnly?: boolean): void;
  add(item: Product): void;
  remove(item: Product): void;
};

export default function useCart(): UseCartResult {
  const {
    add,
    clear,
    remove,
    items,
    //  isLoading
  } = useContext(CartContext);

  // const { post } = useApi();

  const inCart = (v: Product) => items.findIndex(p => p.Id === v.Id) >= 0;

  return {
    add,
    clear,
    remove,
    items,
    inCart,
  };
}

import React from 'react'
import { useScrollTrigger } from '@material-ui/core';

interface Props {
  disable?: boolean;
  window?: () => Window;
  children: React.ReactElement;
}

function ElevationScroll(props: Props) {
  const {window, children, disable} = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: disable ? 0 : trigger ? 4 : 0
  });
}

export default ElevationScroll;
import React from "react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { LinkProps } from "@material-ui/core/Link";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { AnyObject } from "@Types";

import { Link, IconButton } from "@material-ui/core";

const ForwardRouterLink = React.forwardRef<
  HTMLAnchorElement,
  // NextLinkProps & { className: string }
  AnyObject
>(({ href, as, children, ...props }, ref) => (
  <NextLink href={href} as={as}>
    <a ref={ref} {...props}>
      {children}
    </a>
  </NextLink>
));

ForwardRouterLink.displayName = "ForwardRouterLink";

export type LinkIconButtonProps = Omit<
  NextLinkProps & IconButtonProps<typeof Link, LinkProps>,
  "component" | "innerRef"
>;

const LinkIconButton = (props: LinkIconButtonProps) => (
  // @ts-ignore
  <IconButton {...props} component={ForwardRouterLink as any} />
);

export default LinkIconButton;

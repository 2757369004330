import React from "react";
import Link from "../Link";
import Image from "next/image";
import { styled } from "@material-ui/core/styles";
import LogoImage from "/public/Logo.png";

const LogoContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  padding: "4px",
  "& img": {
    height: 30,
    verticalAlign: "top",
    [theme.breakpoints.up("sm")]: {
      height: 40,
      verticalAlign: "middle",
    },
    [theme.breakpoints.up("md")]: {
      height: 40,
    },
  },
  justifyContent: "center",

  [theme.breakpoints.up("sm")]: {
    justifyContent: "flex-start",
  },
}));

export interface LogoProps {
  className?: any;
}

const Logo = ({ className }: LogoProps) => (
  <LogoContainer className={className}>
    <Link href="/">
      <Image src={LogoImage} alt="Trace America" priority />
    </Link>
  </LogoContainer>
);

export default Logo;
